import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ApiModule, Configuration, ConfigurationParameters } from '../api/gen';

import { Router } from '@angular/router';

import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { environment } from '../environments/environment';
import { TenantHeaderInterceptorService } from './util/interceptors/tenant-header-interceptor.service';

import * as Sentry from '@sentry/angular-ivy';
import { ResolverComponent } from './resolver/resolver.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LottieModule } from 'ngx-lottie';
import { SummaryModule } from './summary/summary.module';
import { CustomerChatService } from './chat/customer-chat-service';
import { BrandingColorService } from './services/branding-color.service';
import { InquiryDeletedHeaderComponent } from './summary/inquiry-deleted/inquiry-deleted-header/inquiry-deleted-header.component';

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.apiPath,
  };
  return new Configuration(params);
}

// load `lottie-web` on demand
export function playerFactory() {
  return import(
    /* webpackChunkName: 'lottie-web' */ 'lottie-web/build/player/lottie_svg'
  );
}

@NgModule({
  declarations: [AppComponent, ResolverComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    ApiModule.forRoot(apiConfigFactory),
    BrowserAnimationsModule,
    MatSnackBarModule,
    LottieModule.forRoot({ player: playerFactory }),
    SummaryModule,
    InquiryDeletedHeaderComponent,
  ],
  providers: [
    {
      provide: 'BrandingColorApi',
      useClass: BrandingColorService,
    },
    {
      provide: 'ChatService',
      useClass: CustomerChatService,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        // logErrors: true,
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TenantHeaderInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
