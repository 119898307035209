export const environment = {
  production: true,
  environment: 'production',
  apiPath:
    'https://blankmile-api-production.jollywater-cbb0cfe0.northeurope.azurecontainerapps.io',
  storagePath: 'https://blankmileproduction.blob.core.windows.net',
  downlinkEstimateTestFilePath:
    'https://blankmilepublicprod.blob.core.windows.net/network-estimate-test-files/downlinktestfile',
  participantTimeout: 5000,
  useSentry: true,
  sentryDsn:
    'https://81c615a172875579785e7b9afa1f5309@o4508250093387776.ingest.de.sentry.io/4508256241057872',
};
