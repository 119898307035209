@if (isLoading$()) {
  <div class="loading-container">
    <mat-spinner></mat-spinner>
  </div>
} @else {
  @if (!accessToken$()) {
    <div class="container">
      <div class="inquiry-access-token-container">
        <div *ngIf="companySettings$() as companySettings">
          <app-inquiry-overview-header
            [companyDisplayName]="companySettings.displayName"
            [companyLogoUrl]="companySettings.logoUrl"
          ></app-inquiry-overview-header>
          <app-inquiry-access-token-input
            [inquiryId]="inquiryId"
            (accessTokenSetSuccessfully)="onAccessTokenSetSuccessfully($event)"
          ></app-inquiry-access-token-input>
        </div>
      </div>
    </div>
  } @else {
    <app-inquiry-overview
      [inquiryId]="inquiryId"
      [tenantId]="tenantId"
      [initialAccess]="initialAccess"
      [isPrivate]="false"
      [accessToken]="accessToken$()"
    ></app-inquiry-overview>
  }
}
