import { CreateCustomerAttachment } from '../../../../api/gen';
import { AssociatedCallPeriod } from '../associated-call-period';
import { Origin } from '../origin';
import { Author } from '../author';

export class CreateCustomerAttachmentRequest {
  storageIdentifier?: string;
  blobPath?: string | null;
  period?: AssociatedCallPeriod;
  inquiryIdentifier?: string;
  fileName?: string | null;
  extension?: string | null;
  note?: string | null;
  origin?: Origin;
  author?: Author;
  createThumbnail?: boolean;

  static toRequest(
    r: CreateCustomerAttachmentRequest,
  ): CreateCustomerAttachment {
    return {
      storageIdentifier: r.storageIdentifier,
      blobPath: r.blobPath,
      period: r.period,
      inquiryIdentifier: r.inquiryIdentifier,
      fileName: r.fileName,
      extension: r.extension,
      note: r.note,
      origin: r.origin,
      author: r.author,
      createThumbnail: r.createThumbnail,
    } as CreateCustomerAttachment;
  }
}
