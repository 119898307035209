import { Component } from '@angular/core';

@Component({
  selector: 'app-inquiry-deleted-header',
  standalone: true,
  templateUrl: './inquiry-deleted-header.component.html',
  styleUrls: ['./inquiry-deleted-header.component.scss'],
})
export class InquiryDeletedHeaderComponent {
  constructor() {}
}
