import { Component, Input } from '@angular/core';
import { AccessTokenLinkComponent } from '../access-token-link/access-token-link.component';
import {
  MatCard,
  MatCardContent,
  MatCardFooter,
  MatCardHeader,
  MatCardTitle,
} from '@angular/material/card';

@Component({
  selector: 'app-initial-access',
  standalone: true,
  imports: [
    AccessTokenLinkComponent,
    MatCard,
    MatCardTitle,
    MatCardHeader,
    MatCardContent,
    MatCardFooter,
  ],
  templateUrl: './initial-access.component.html',
  styleUrl: './initial-access.component.scss',
})
export class InitialAccessComponent {
  @Input() inquiryId: string;
  @Input() accessToken: string;
}
