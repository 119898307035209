import { Component } from '@angular/core';
import { InquiryDeletedHeaderComponent } from './inquiry-deleted-header/inquiry-deleted-header.component';
import { LottieComponent } from 'ngx-lottie';

@Component({
  selector: 'app-inquiry-deleted',
  standalone: true,
  templateUrl: './inquiry-deleted.component.html',
  styleUrls: ['./inquiry-deleted.component.scss'],
  imports: [InquiryDeletedHeaderComponent, LottieComponent],
})
export class InquiryDeletedComponent {}
