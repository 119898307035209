import { Component, signal } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { BrandingDataService } from 'aidar-branding';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  protected isLoading = signal(true);

  constructor(
    private readonly titleService: Title,
    private readonly brandingService: BrandingDataService,
    readonly iconRegistry: MatIconRegistry,
    readonly domSanitizer: DomSanitizer,
  ) {
    this.titleService.setTitle('blankmile');

    this.brandingService.applyBranding().subscribe({
      next: (res) => {
        this.isLoading.set(false);
      },
      error: () => {
        this.isLoading.set(false);
      },
    });

    iconRegistry.addSvgIcon(
      'animated_images',
      domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/animated_images.svg',
      ),
    );
  }
}
