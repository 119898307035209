<div class="access-token-input-container">
  <h2 i18n>Eingeschränkter Zugang</h2>
  <p i18n>
    Der Zugang zur Anfrage ist eingeschränkt. Daten und Informationen stehen nur
    nach Eingabe des korrekten Zugangscodes zur Verfügung.
  </p>
  <mat-form-field>
    <mat-label i18n>Zugangscode</mat-label>
    <input
      matInput
      [formControl]="accessToken"
      (blur)="updateErrorMessage()"
      required
    />
    @if (accessToken.invalid) {
      <mat-error>{{ errorMessage$() }}</mat-error>
    }
  </mat-form-field>

  @if (isLoading$()) {
    <div class="loading-spinner">
      <mat-spinner diameter="30"></mat-spinner>
    </div>
  } @else {
    <button
      mat-flat-button
      color="primary"
      [disabled]="accessToken.invalid"
      (click)="onSendAccessToken()"
    >
      Absenden
    </button>
  }
</div>
