import { InquiryAccessInfoDto } from '../../../api/gen';
import { InquiryAccessType } from './inquiry-access-type';

export class InquiryAccessInfo {
  accessType?: InquiryAccessType;

  static fromDto(dto: InquiryAccessInfoDto): InquiryAccessInfo {
    return { accessType: dto.accessType } as InquiryAccessInfo;
  }
}
