import { AccessInquiryToken, InquiryAccessService as Api } from '../../api/gen';
import { map, Observable } from 'rxjs';
import { InquiryAccessInfo } from '../model/inquiry/inquiry-access-info';
import { InquiryAccess } from '../model/inquiry/inquiry-access';
import { Injectable } from '@angular/core';
import { InquiryAccessToken } from '../model/inquiry/inquiry-access-token';

@Injectable()
export class InquiryAccessService {
  constructor(private readonly inquiryAccessService: Api) {}

  getInquiryAccessInfo(
    inquiryIdentifier: string,
  ): Observable<InquiryAccessInfo> {
    return this.inquiryAccessService
      .customerContactInquiryAccessInquiryIdentifierGet(inquiryIdentifier)
      .pipe(map((dto) => InquiryAccessInfo.fromDto(dto)));
  }

  accessInquiry(
    inquiryIdentifier: string,
    accessToken: string | null = null,
  ): Observable<InquiryAccess> {
    const request = { accessToken: accessToken } as AccessInquiryToken;
    return this.inquiryAccessService
      .customerContactInquiryAccessInquiryIdentifierPost(
        inquiryIdentifier,
        request,
      )
      .pipe(map((dto) => InquiryAccess.fromDto(dto)));
  }

  generateInquiryAccessToken(
    inquiryIdentifier: string,
  ): Observable<InquiryAccessToken> {
    return this.inquiryAccessService
      .customerContactInquiryAccessInquiryIdentifierGeneratePost(
        inquiryIdentifier,
      )
      .pipe(map((dto) => InquiryAccessToken.fromDto(dto)));
  }
}
