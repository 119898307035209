@if (isLoading$()) {
  <div class="loading-container">
    <mat-spinner></mat-spinner>
    <h2 i18n>Lade Anfrage...</h2>
  </div>
} @else {
  @switch (accessType$()) {
    @case (InquiryAccessType.HelperOnly) {
      <app-inquiry-overview
        [inquiryId]="inquiryId"
        [tenantId]="tenantId"
        [initialAccess]="false"
        [isPrivate]="true"
      ></app-inquiry-overview>
    }
    @case (InquiryAccessType.AccessToken) {
      <app-inquiry-access-token
        [inquiryId]="inquiryId"
        [tenantId]="tenantId"
        [initialAccess]="this.initialAccess"
      ></app-inquiry-access-token>
    }
    @default {
      <app-inquiry-overview
        [inquiryId]="inquiryId"
        [tenantId]="tenantId"
        [initialAccess]="false"
        [isPrivate]="false"
      ></app-inquiry-overview>
    }
  }
}
