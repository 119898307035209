import { BrandingColor } from 'aidar-branding';
import { BrandingColorDto } from '../../api/gen';

export class BrandingColorDtoCasts {
  static fromDto(dto: BrandingColorDto): BrandingColor {
    return {
      primaryColorHex: dto.primaryColorHex,
      accentColorHex: dto.accentColorHex,
      warnColorHex: dto.warnColorHex,
      deletedAt: dto.deletedAt,
    } as BrandingColor;
  }
}
