import { AsyncPipe, DatePipe, NgForOf, NgIf } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject, take } from 'rxjs';
import { InquiryState } from '../../../api/gen';
import { InvitationStatusService } from '../../services/invitation-status.service';
import { InquirySummaryService } from '../../services/inquiry-summary.service';
import { InquiryAccessService } from '../../services/inquiry-access.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatCard, MatCardImage, MatCardSubtitle } from '@angular/material/card';
import { InitialAccessComponent } from '../initial-access/initial-access.component';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { FileUploadModule } from '../../file-upload/file-upload.module';
import { DeletionInfoComponent } from './deletion-info/deletion-info.component';
import { AttachmentsGalleryComponent } from './attachments-gallery/attachments-gallery.component';
import { ChatHistoryComponent } from './chat-history/chat-history.component';
import { InquiryOverviewHeaderComponent } from './inquiry-overview-header/inquiry-overview-header.component';
import { InquiryCallInfoComponent } from './inquiry-call-info/inquiry-call-info.component';
import { InquiryPrivateCardComponent } from './inquiry-private-card/inquiry-private-card.component';

@Component({
  selector: 'app-inquiry-overview',
  standalone: true,
  templateUrl: './inquiry-overview.component.html',
  styleUrls: ['./inquiry-overview.component.scss'],
  providers: [
    InvitationStatusService,
    InquirySummaryService,
    InquiryAccessService,
  ],
  imports: [
    MatProgressSpinner,
    NgIf,
    AsyncPipe,
    MatCard,
    MatCardSubtitle,
    InitialAccessComponent,
    MatIcon,
    MatButton,
    MatMenuTrigger,
    MatMenu,
    NgForOf,
    MatCardImage,
    MatIconButton,
    FileUploadModule,
    DatePipe,
    DeletionInfoComponent,
    AttachmentsGalleryComponent,
    ChatHistoryComponent,
    InquiryOverviewHeaderComponent,
    InquiryCallInfoComponent,
    InquiryPrivateCardComponent,
  ],
})
export class InquiryOverviewComponent implements OnInit, OnDestroy {
  @Input() initialAccess: boolean;
  @Input() inquiryId: string;
  @Input() tenantId: string;
  @Input() isPrivate: boolean;
  @Input() accessToken: string;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly changeDetector: ChangeDetectorRef,
    protected readonly inquirySummaryService: InquirySummaryService,
  ) {}

  ngOnInit(): void {
    this.refreshInquiryData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected refreshInquiryData() {
    if (this.inquiryId) {
      this.inquirySummaryService
        .loadInquirySummary(this.inquiryId, this.accessToken)
        .subscribe({
          next: (result) => {
            if (result.state === InquiryState.Deleted) {
              this.routeToDeleted();
            }
            this.changeDetector.detectChanges();
          },
          error: () => {
            const snackbar = this.snackBar.open(
              $localize`Daten konnten nicht geladen werden!`,
              $localize`Erneut versuchen`,
            );
            snackbar
              .onAction()
              .pipe(take(1))
              .subscribe(() => {
                this.refreshInquiryData();
                snackbar.dismiss();
              });
          },
        });
    }
  }

  private routeToDeleted() {
    this.router.navigate(['summary/deleted'], {
      queryParams: {
        inquiryId: this.inquiryId,
        tenantId: this.tenantId,
      },
    });
  }
}
