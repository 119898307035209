import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-access-token-link',
  standalone: true,
  imports: [MatIcon],
  templateUrl: './access-token-link.component.html',
  styleUrl: './access-token-link.component.scss',
})
export class AccessTokenLinkComponent {
  @Input() accessToken: string;

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly clipboard: Clipboard,
  ) {}

  protected copyToClipboard() {
    this.clipboard.copy(this.accessToken);
    this.snackBar.open($localize`📋 Zugangscode kopiert`, null, {
      duration: 2500,
    });
  }
}
