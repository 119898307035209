<app-inquiry-deleted-header></app-inquiry-deleted-header>
<div class="loading-spinner-container">
  <mat-spinner *ngIf="!displayError"></mat-spinner>
  <div class="error-container" *ngIf="displayError">
    <ng-lottie
      width="20rem"
      [options]="{ path: 'assets/animations/lost.json' }"
    ></ng-lottie>
    <span i18n
      >Whoops. Dieser Link funktioniert nicht.<br />
      Bitte frage deinen Kontakt um einen neuen.</span
    >
  </div>
</div>
