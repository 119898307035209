<div class="container">
  <div class="header">
    <button mat-button color="primary" (click)="closeSheet()" i18n>
      Schließen
    </button>
  </div>
  <div class="content">
    <app-live-chat
      *ngIf="inquiryId"
      [inquiryId]="inquiryId"
      [displayInput]="displayInput"
    ></app-live-chat>
  </div>
</div>
