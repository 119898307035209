<div class="container">
  @if ((inquirySummaryService.getInquirySummary() | async) === null) {
    <div class="container-loading">
      <mat-spinner></mat-spinner>
    </div>
  } @else {
    <div *ngIf="inquirySummaryService.getInquirySummary() | async as inquiry">
      <app-inquiry-overview-header
        [companyDisplayName]="inquiry.companyDisplayName"
        [companyLogoUrl]="inquiry.companyLogoUrl"
      ></app-inquiry-overview-header>
      <div class="card-container">
        <app-inquiry-call-info [inquiry]="inquiry"></app-inquiry-call-info>
        @if (!isPrivate) {
          @if (initialAccess) {
            <app-initial-access
              [inquiryId]="inquiryId"
              [accessToken]="accessToken"
            ></app-initial-access>
          }
          <app-chat-history [inquiry]="inquiry" />
          <app-attachments-gallery
            [inquiry]="inquiry"
            (filesUploaded)="refreshInquiryData()"
          ></app-attachments-gallery>
        } @else {
          <app-inquiry-private-card
            [inquiryId]="inquiryId"
            [companyDisplayName]="inquiry.companyDisplayName"
          ></app-inquiry-private-card>
        }
      </div>
      <app-deletion-info [inquiry]="inquiry"></app-deletion-info>
    </div>
  }
</div>
