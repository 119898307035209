<mat-card class="call-info-card">
  <mat-card-header>
    <mat-card-title i18n>Informationen zur Anfrage</mat-card-title>
    <mat-card-subtitle>{{
      inquiry.scheduledFor | date: "EEE d MMMM"
    }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    {{ phoneNumber }}
  </mat-card-content>
</mat-card>
