@if (accessToken) {
  <mat-card class="initial-access-card">
    <mat-card-header>
      <mat-card-title i18n>Zugangscode</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <span i18n
        >Falls Sie später wieder auf die Dateien der Anfrage zugreifen möchten,
        notieren Sie sich folgenden Zugangscode</span
      >
    </mat-card-content>
    <mat-card-footer class="initial-access-card-footer">
      <app-access-token-link
        class="initial-access-card-footer-link"
        [accessToken]="accessToken"
      ></app-access-token-link>
    </mat-card-footer>
  </mat-card>
}
