import { Component, Input } from '@angular/core';
import {
  MatCard,
  MatCardContent,
  MatCardHeader,
  MatCardSubtitle,
  MatCardTitle,
} from '@angular/material/card';
import { InquirySummary } from '../../../model/inquiry-summary';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-inquiry-call-info',
  standalone: true,
  imports: [
    MatCard,
    MatCardTitle,
    MatCardSubtitle,
    MatCardHeader,
    DatePipe,
    MatCardContent,
  ],
  templateUrl: './inquiry-call-info.component.html',
  styleUrl: './inquiry-call-info.component.scss',
})
export class InquiryCallInfoComponent {
  @Input() inquiry: InquirySummary;
  phoneNumber: string;

  constructor() {
    this.phoneNumber =
      this.inquiry?.phoneNumber ?? $localize`Keine Telefonnummer angegeben`;
  }
}
