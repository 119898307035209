import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-inquiry-overview-header',
  standalone: true,
  imports: [NgIf],
  templateUrl: './inquiry-overview-header.component.html',
  styleUrl: './inquiry-overview-header.component.scss',
})
export class InquiryOverviewHeaderComponent {
  @Input() companyLogoUrl: string;
  @Input() companyDisplayName: string;
}
