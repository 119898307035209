import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { AttachmentFileService } from '../attachment-file.service';
import {
  UploadFilesSnackbarComponent,
  UploadFilesSnackbarData,
} from '../upload-files-snackbar/upload-files-snackbar.component';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { delay } from 'rxjs';
import { AssociatedCallPeriod } from '../../model/attachment/associated-call-period';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
  // TODO: this could be a service as well
  // it does not need a template, as we could create the input inmemory (we are doing this in AddAttachmentButtonComponent already)

  @ViewChild('fileUpload', { static: true })
  private readonly input: ElementRef;

  @Input()
  inquiryId: string;

  @Output()
  public filesUploaded: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  public callPeriod?: AssociatedCallPeriod;

  constructor(
    private readonly fileService: AttachmentFileService,
    private readonly snackBar: MatSnackBar,
  ) {}

  public selectAndUploadFiles(): void {
    this.input.nativeElement.click();
  }

  protected onFilesSelected(event: Event) {
    if (event.target) {
      const eventTarget = event.target as HTMLInputElement;

      if (eventTarget.files) {
        this.uploadFiles(eventTarget.files);
      }
    }
  }

  /*private async getFilesToUpload(files: FileList): Promise<[File[], number]> {
    const inquirySummary = await firstValueFrom(
      this.inquirySummaryService.getInquirySummary()
    );
    const attachments = inquirySummary.attachments;

    const filesToUpload: File[] = [];
    let numberOfFilesAlreadyUploaded = 0;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (attachments.find((a) => a.fileName === file.name)) {
        numberOfFilesAlreadyUploaded += 1;
      } else {
        filesToUpload.push(file);
      }
    }

    return [filesToUpload, numberOfFilesAlreadyUploaded];
  }*/

  // Todo: How should we handle overwriting files?
  private async uploadFiles(files: FileList) {
    const snackbar = this.snackBar.openFromComponent(
      UploadFilesSnackbarComponent,
      {
        data: {
          numberOfFilesAlreadyProcessed: 0,
          numberOfFilesToProcess: files.length,
        } as UploadFilesSnackbarData,
      },
    );

    const filesForUpload: File[] = [];
    for (let i = 0; i < files.length; i++) {
      filesForUpload.push(files.item(i));
    }

    this.fileService
      .uploadAttachments(
        this.callPeriod ?? AssociatedCallPeriod.After,
        this.inquiryId,
        filesForUpload,
      )
      .pipe(delay(1000))
      .subscribe({
        next: (attachment) => {
          snackbar.instance.onAttachmentUploaded(attachment);
        },
        error: () => {
          this.finishUploading(snackbar);
        },
        complete: () => {
          this.finishUploading(snackbar);
        },
      });
  }

  private finishUploading(
    snackbar: MatSnackBarRef<UploadFilesSnackbarComponent>,
  ) {
    snackbar.dismiss();
    this.filesUploaded.emit();
  }
}
