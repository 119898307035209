import { InquiryAccessDto } from '../../../api/gen';
import { InquiryAccessType } from './inquiry-access-type';
import { InquiryAccessError } from './inquiry-access-error';

export class InquiryAccess {
  accessType?: InquiryAccessType;
  canAccess?: boolean;
  error?: InquiryAccessError;

  static fromDto(dto: InquiryAccessDto): InquiryAccess {
    return {
      accessType: dto.accessType,
      canAccess: dto.canAccess,
      error: dto.error,
    } as InquiryAccess;
  }
}
