export * from './analytics.service';
import { AnalyticsService } from './analytics.service';
export * from './appointment.service';
import { AppointmentService } from './appointment.service';
export * from './attachment.service';
import { AttachmentService } from './attachment.service';
export * from './authenticationToken.service';
import { AuthenticationTokenService } from './authenticationToken.service';
export * from './brandingColor.service';
import { BrandingColorService } from './brandingColor.service';
export * from './company.service';
import { CompanyService } from './company.service';
export * from './featureTracking.service';
import { FeatureTrackingService } from './featureTracking.service';
export * from './inquiryAccess.service';
import { InquiryAccessService } from './inquiryAccess.service';
export * from './inquirySummary.service';
import { InquirySummaryService } from './inquirySummary.service';
export * from './invitationStatus.service';
import { InvitationStatusService } from './invitationStatus.service';
export * from './liveChat.service';
import { LiveChatService } from './liveChat.service';
export * from './liveChatTranslation.service';
import { LiveChatTranslationService } from './liveChatTranslation.service';
export * from './ping.service';
import { PingService } from './ping.service';
export * from './syncAppointment.service';
import { SyncAppointmentService } from './syncAppointment.service';
export * from './tinyLinkResolver.service';
import { TinyLinkResolverService } from './tinyLinkResolver.service';
export const APIS = [AnalyticsService, AppointmentService, AttachmentService, AuthenticationTokenService, BrandingColorService, CompanyService, FeatureTrackingService, InquiryAccessService, InquirySummaryService, InvitationStatusService, LiveChatService, LiveChatTranslationService, PingService, SyncAppointmentService, TinyLinkResolverService];
