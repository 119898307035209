import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ResolverComponent } from './resolver/resolver.component';

const routes: Routes = [
  {
    path: 'video-appointment',
    loadChildren: () =>
      import('./video-appointment/video-appointment.module').then(
        (m) => m.VideoAppointmentModule,
      ),
  },
  {
    path: 'summary',
    loadChildren: () =>
      import('./summary/summary.module').then((m) => m.SummaryModule),
  },
  {
    path: 'join',
    loadChildren: () => import('./join/join.module').then((m) => m.JoinModule),
  },
  {
    path: 'data-privacy',
    loadChildren: () =>
      import('./data-privacy/data-privacy.module').then(
        (m) => m.DataPrivacyModule,
      ),
  },
  {
    path: 'not-found',
    redirectTo: 'data-privacy', // Martin: This is only symbolic and should be replaced
  },
  {
    path: '**',
    component: ResolverComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
