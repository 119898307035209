import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getPlatform, Platform } from '../../../detectPlatform';
import { Platform as AngularPlatform } from '@angular/cdk/platform';
import {
  AidarPermissionStatus,
  PermissionService,
} from '../permission.service';
import { MatIcon } from '@angular/material/icon';
import {
  MatCard,
  MatCardActions,
  MatCardContent,
  MatCardHeader,
  MatCardTitle,
} from '@angular/material/card';
import { MatButton } from '@angular/material/button';
import { NgIf, NgTemplateOutlet } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-missing-permissions',
  templateUrl: './missing-permissions.component.html',
  styleUrls: ['./missing-permissions.component.scss'],
  providers: [AngularPlatform],
  imports: [
    MatIcon,
    MatCardActions,
    MatButton,
    MatCard,
    MatCardHeader,
    MatCardContent,
    MatCardTitle,
    NgTemplateOutlet,
    NgIf,
  ],
})
export class MissingPermissionsComponent implements OnDestroy {
  private readonly unsubscribe$ = new Subject<void>();
  protected micPermissionMissing = false;
  protected camPermissionMissing = false;
  protected blockedOnSystemLevel = false;
  protected isAndroid = false;

  protected titleMicrophone = $localize`Mikrofon`;
  protected titleCamera = $localize`Kamera`;

  constructor(
    readonly permissionService: PermissionService,
    public platform: AngularPlatform,
  ) {
    this.isAndroid = getPlatform() === Platform.Android;
    permissionService
      .observeChanges()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((changes) => {
        if (changes.currentCamStatus !== AidarPermissionStatus.Granted)
          this.camPermissionMissing = true;
        if (changes.currentMicStatus !== AidarPermissionStatus.Granted)
          this.micPermissionMissing = true;

        if (
          changes.currentCamStatus ===
            AidarPermissionStatus.DeniedOnSystemLevel ||
          changes.currentMicStatus === AidarPermissionStatus.DeniedOnSystemLevel
        ) {
          this.blockedOnSystemLevel = true;
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  reloadPage() {
    location.reload();
  }

  getBrowserName(): string {
    if (this.platform.SAFARI) return 'Safari';
    if (this.platform.FIREFOX) return 'Firefox';
    else return $localize`Ihres Browsers`;
  }
}
