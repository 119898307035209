<mat-card class="attachments-card">
  <mat-card-header>
    <mat-card-title i18n>Anhänge</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div
      class="attachments-gallery"
      *ngIf="inquiry.attachments.length > 0; else attachmentsGalleryFallback"
    >
      <mat-card
        *ngFor="let attachment of inquiry.attachments"
        class="single-attachment-card"
        (click)="onDownloadAttachment(attachment)"
      >
        <div class="attachment-thumbnail" mat-card-image>
          @if (attachment.type === AttachmentContentType.Image) {
            <img
              src="{{
                attachment.previewSource ?? 'assets/images/description.svg'
              }}"
            />
          } @else if (attachment.type === AttachmentContentType.LivePhoto) {
            <video [src]="attachment.blobUrl" loop autoplay muted></video>
          } @else if (
            attachment.type === AttachmentContentType.Pdf ||
            attachment.type === AttachmentContentType.None
          ) {
            <img src="assets/images/description.svg" />
          }
        </div>

        <div class="attachment-information">
          <span class="attachment-information-span">{{
            attachment.fileName
          }}</span>
          <mat-card-subtitle
            >{{
              dateFormatterService.formatAttachmentDate(attachment.createdAt)
            }}
          </mat-card-subtitle>
        </div>

        <button
          *ngIf="
            !(alreadyDownloadedAttachments$ | async).includes(
              attachment.attachmentIdentifier
            );
            else attachmentAlreadyDownloaded
          "
          [disabled]="downloading$ | async"
          aria-label="Download"
          class="attachment-download"
          color="primary"
          mat-icon-button
        >
          <mat-icon>download</mat-icon>
        </button>

        <ng-template #attachmentAlreadyDownloaded>
          <button
            (click)="onAlreadyDownloadedAttachment(attachment)"
            [disabled]="downloading$ | async"
            aria-label="Download"
            class="attachment-download"
            mat-icon-button
            style="color: green"
          >
            <mat-icon>check</mat-icon>
          </button>
        </ng-template>
      </mat-card>
    </div>
  </mat-card-content>

  <mat-card-footer class="attachment-card-footer">
    <button
      mat-raised-button
      color="primary"
      class="attachment-card-footer-button"
      (click)="fileUpload.selectAndUploadFiles()"
      [style.margin-top]="inquiry.attachments.length > 0 ? '1rem' : '0'"
    >
      <mat-icon>upload</mat-icon>
      <span i18n>Dateien hochladen</span>
    </button>
  </mat-card-footer>
</mat-card>

<app-file-upload
  #fileUpload
  (filesUploaded)="emitFilesUploaded()"
  [inquiryId]="inquiry.inquiryIdentifier"
  [callPeriod]="'After'"
></app-file-upload>

<ng-template #attachmentsGalleryFallback>
  <div class="attachments-gallery-fallback">
    <span class="attachments-gallery-fallback-text" i18n
      >Zurzeit sind noch keine Anhänge verfügbar. Wenn Sie Daten mit
      <b>{{ inquiry.companyDisplayName }}</b> austauschen möchten, können Sie
      diese hier hochladen.
    </span>
  </div>
</ng-template>
