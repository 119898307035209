import { AttachmentService as Api } from '../../api/gen';
import { AttachmentUpload } from '../model/attachment/attachment-upload';
import { map, Observable } from 'rxjs';
import { CreateAttachmentUpload } from '../model/attachment/request/create-attachment-upload';
import { Attachment } from '../model/attachment/attachment';
import { CreateCustomerAttachmentRequest } from '../model/attachment/request/create-customer-attachment-request';
import { SharedAttachmentFiltersRequest } from '../model/attachment/request/shared-attachment-filters-request';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AttachmentService {
  constructor(private readonly attachmentService: Api) {}

  createAttachmentUploadRequest(
    inquiryIdentifier: string,
    fileNameWithExtension: string,
  ): Observable<AttachmentUpload> {
    const request = {
      inquiryIdentifier: inquiryIdentifier,
      fileNameWithExtension: fileNameWithExtension,
    } as CreateAttachmentUpload;
    return this.attachmentService
      .customerAttachmentUploadPost(CreateAttachmentUpload.toRequest(request))
      .pipe(map((dto) => AttachmentUpload.fromDto(dto)));
  }

  createCustomerAttachment(
    request: CreateCustomerAttachmentRequest,
  ): Observable<Attachment> {
    return this.attachmentService
      .customerAttachmentPost(
        CreateCustomerAttachmentRequest.toRequest(request),
      )
      .pipe(map((dto) => Attachment.fromDto(dto)));
  }

  deleteCustomerAttachment(attachmentId: string): Observable<Attachment> {
    return this.attachmentService
      .customerAttachmentAttachmentIdDelete(attachmentId)
      .pipe(map((dto) => Attachment.fromDto(dto)));
  }

  getAttachmentsForInquiry(
    inquiryId: string,
    filters: SharedAttachmentFiltersRequest,
  ): Observable<Attachment[]> {
    return this.attachmentService
      .customerAttachmentInquiryInquiryIdPost(inquiryId, filters)
      .pipe(map((x) => x.map((dto) => Attachment.fromDto(dto))));
  }
}
