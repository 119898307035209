import { AttachmentDto, Author, Origin } from '../../../api/gen';
import { AssociatedCallPeriod } from './associated-call-period';
import { AttachmentContentType } from './attachment-content-type';
import { AttachmentContentTypeUtils } from '../../../../../helper-client/src/app/model/attachment/attachment-content-type';

export class Attachment {
  previewSource: string;

  containerName?: string | null;
  inquiryIdentifier?: string;
  attachmentIdentifier?: string | null;
  fileName?: string | null;
  note?: string | null;
  extension?: string | null;
  type: AttachmentContentType;
  contentType?: string | null;
  blobUrl?: string | null;
  blobPath?: string | null;
  thumbnailUrl?: string | null;
  annotationBlobUrl?: string | null;
  annotationThumbnailUrl?: string | null;
  createdAt?: string;
  period?: AssociatedCallPeriod;
  origin?: Origin;
  author?: Author;
  shared?: boolean;
  isInProcess?: boolean;
  processPercent?: number | null;
  tenantId?: string | null;

  static fromDto(dto: AttachmentDto): Attachment {
    return {
      previewSource: dto.annotationThumbnailUrl
        ? dto.annotationThumbnailUrl
        : dto.thumbnailUrl,

      containerName: dto.containerName,
      inquiryIdentifier: dto.inquiryIdentifier,
      attachmentIdentifier: dto.attachmentIdentifier,
      fileName: dto.fileName,
      note: dto.note,
      extension: dto.extension,
      type: AttachmentContentTypeUtils.fromContentType(dto.contentType),
      contentType: dto.contentType,
      blobUrl: dto.blobUrl,
      blobPath: dto.blobPath,
      thumbnailUrl: dto.thumbnailUrl,
      annotationBlobUrl: dto.annotationBlobUrl,
      annotationThumbnailUrl: dto.annotationThumbnailUrl,
      createdAt: dto.createdAt,
      period: dto.period,
      origin: dto.origin,
      author: dto.author,
      shared: dto.shared,
      isInProcess: dto.isInProcess,
      processPercent: dto.processPercent,
      tenantId: dto.tenantId,
    };
  }
}
