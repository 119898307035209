import { Component, Input, OnInit, signal } from '@angular/core';
import { InquiryOverviewHeaderComponent } from '../inquiry-overview/inquiry-overview-header/inquiry-overview-header.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { CompanyService } from '../../services/company.service';
import { CompanySettingsPublic } from '../../model/company-settings-public';
import { ReactiveFormsModule } from '@angular/forms';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { InquiryOverviewComponent } from '../inquiry-overview/inquiry-overview.component';
import { InquiryAccessTokenInputComponent } from '../inquiry-access-token-input/inquiry-access-token-input.component';
import { InquiryAccessService } from '../../services/inquiry-access.service';
import { switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-inquiry-access-token',
  standalone: true,
  imports: [
    InquiryOverviewHeaderComponent,
    AsyncPipe,
    NgIf,
    MatFormField,
    ReactiveFormsModule,
    MatInput,
    MatError,
    MatLabel,
    MatButton,
    MatProgressSpinner,
    InquiryOverviewComponent,
    InquiryAccessTokenInputComponent,
  ],
  templateUrl: './inquiry-access-token.component.html',
  styleUrl: './inquiry-access-token.component.scss',
  providers: [CompanyService, InquiryAccessService],
})
export class InquiryAccessTokenComponent implements OnInit {
  @Input() initialAccess: boolean;
  @Input() inquiryId: string;
  @Input() tenantId: string;

  protected isLoading$ = signal(true);
  protected accessToken$ = signal<string | null>(null);
  protected companySettings$ = signal<CompanySettingsPublic | null>(null);

  constructor(
    protected readonly companyService: CompanyService,
    private readonly inquiryAccessService: InquiryAccessService,
  ) {}

  ngOnInit(): void {
    if (this.inquiryId) {
      this.companyService
        .getPublicCompanyInformation()
        .pipe(
          tap((companySettings) => {
            this.companySettings$.set(companySettings);
          }),
          switchMap(() =>
            this.inquiryAccessService.generateInquiryAccessToken(
              this.inquiryId,
            ),
          ),
        )
        .subscribe((iat) => {
          this.accessToken$.set(iat.accessToken);
          this.isLoading$.set(false);
        });
    }
  }

  onAccessTokenSetSuccessfully(token: string) {
    this.initialAccess = false;
    this.accessToken$.set(token);
  }
}
