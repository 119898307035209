import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TenantInfoService {
  private static readonly DEFAULT_SUBDOMAIN: string = 'madtech';
  private static readonly AIDAR_SUBDOMAIN: string = 'aidar';
  private static readonly WWW_SUBDOMAIN: string = 'www';

  private tenantIdentifier: string;

  constructor() {
    this.tenantIdentifier = TenantInfoService.getSubdomain();
  }

  /**
   * @returns the subdomain of the current hostname, if no subdomain is found an empty string is returned
   */
  private static getSubdomain(): string {
    let subdomain: string;
    const domain = window.location.hostname;
    if (
      domain.indexOf('.') < 0 ||
      domain.split('.')[0] === TenantInfoService.AIDAR_SUBDOMAIN ||
      domain.split('.')[0] === TenantInfoService.WWW_SUBDOMAIN ||
      !Number.isNaN(+domain.split('.')[0])
    ) {
      subdomain = TenantInfoService.DEFAULT_SUBDOMAIN;
    } else {
      subdomain = domain.split('.')[0];
    }

    return subdomain;
  }

  public getTenantIdentifier(): string {
    return this.tenantIdentifier;
  }

  public updateTenantIdentifier(identifier: string) {
    this.tenantIdentifier = identifier;
  }
}
