import { Component, Input, OnInit } from '@angular/core';
import {
  MatCard,
  MatCardContent,
  MatCardFooter,
  MatCardHeader,
  MatCardTitle,
} from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { ChatSheetComponent } from '../../../chat/chat-sheet/chat-sheet.component';
import { InquirySummary } from '../../../model/inquiry-summary';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-chat-history',
  standalone: true,
  imports: [
    MatCard,
    MatIcon,
    MatButton,
    MatCardTitle,
    MatCardHeader,
    MatCardContent,
    MatCardFooter,
  ],
  templateUrl: './chat-history.component.html',
  styleUrl: './chat-history.component.scss',
})
export class ChatHistoryComponent implements OnInit {
  @Input() inquiry: InquirySummary;

  constructor(private readonly bottomSheet: MatBottomSheet) {}

  ngOnInit(): void {
    console.log('here');
  }

  public openChat() {
    this.bottomSheet.open(ChatSheetComponent, {
      panelClass: 'live-chat-bottom-sheet',
      data: { inquiryId: this.inquiry.inquiryIdentifier, displayInput: false },
    });
  }
}
