import { InquiryJoinDto } from '../../api/gen';
import { InquiryState } from './inquiry-state';

export class InquiryJoin {
  private static readonly fallbackLogoUrl =
    'https://madtech.at/wp-content/uploads/2023/01/madtech-logo-quer-quadratisch.png';

  inquiryIdentifier?: string;
  firstName?: string | null;
  lastName?: string | null;
  state?: InquiryState;
  companyDisplayName?: string | null;
  companyLogoUrl?: string | null;

  static fromDto(dto: InquiryJoinDto): InquiryJoin {
    return {
      inquiryIdentifier: dto.inquiryIdentifier,
      firstName: dto.firstName,
      lastName: dto.lastName,
      state: dto.state,
      companyDisplayName: dto.companyDisplayName,
      companyLogoUrl:
        dto.companyLogoUrl === null
          ? InquiryJoin.fallbackLogoUrl
          : dto.companyLogoUrl,
    } as InquiryJoin;
  }
}
