export type InquiryAccessError =
  | 'None'
  | 'HelperOnly'
  | 'WrongAccessToken'
  | 'AccessTokenEnteredTooOften'
  | 'Unknown';

export const InquiryAccessError = {
  None: 'None' as InquiryAccessError,
  HelperOnly: 'HelperOnly' as InquiryAccessError,
  WrongAccessToken: 'WrongAccessToken' as InquiryAccessError,
  AccessTokenEnteredTooOften:
    'AccessTokenEnteredTooOften' as InquiryAccessError,
  Unknown: 'Unknown' as InquiryAccessError,
};
