import { InquirySummaryDto } from '../../api/gen';
import { Attachment } from './attachment/attachment';
import { InquiryState } from './inquiry-state';

export class InquirySummary {
  inquiryIdentifier?: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  street?: string | null;
  houseNumber?: string | null;
  zipCode?: string | null;
  city?: string | null;
  notes?: string | null;
  state?: InquiryState;
  scheduledForConfirmed?: string | null;
  scheduledForDeclined?: string | null;
  scheduledFor?: string;
  autoDeletionScheduledAt?: string | null;
  autoDeletedAt?: string | null;
  manuallyDeletedAt?: string | null;
  manuallyDeletedBy?: string | null;
  attachments?: Array<Attachment> | null;
  companyDisplayName?: string | null;
  companyLogoUrl?: string | null;

  static fromDto(dto: InquirySummaryDto): InquirySummary {
    return {
      inquiryIdentifier: dto.inquiryIdentifier,
      firstName: dto.firstName,
      lastName: dto.lastName,
      email: dto.email,
      phoneNumber: dto.email,
      street: dto.street,
      houseNumber: dto.houseNumber,
      zipCode: dto.zipCode,
      city: dto.city,
      notes: dto.notes,
      state: dto.state,
      scheduledForConfirmed: dto.scheduledForConfirmed,
      scheduledForDeclined: dto.scheduledForDeclined,
      scheduledFor: dto.scheduledFor,
      autoDeletionScheduledAt: dto.autoDeletionScheduledAt,
      autoDeletedAt: dto.autoDeletedAt,
      manuallyDeletedAt: dto.manuallyDeletedAt,
      manuallyDeletedBy: dto.manuallyDeletedBy,
      attachments: dto.attachments.map((dto) => Attachment.fromDto(dto)),
      companyDisplayName: dto.companyDisplayName,
      companyLogoUrl: dto.companyLogoUrl,
    } as InquirySummary;
  }
}
