@if (inquiry.autoDeletionScheduledAt) {
  <div class="deletion-notes-container">
    <span class="deletion-notes-text" i18n
      >Die Daten stehen bis zum
      {{ inquiry.autoDeletionScheduledAt | date: "dd.MM.yy" }} um
      {{ inquiry.autoDeletionScheduledAt | date: "HH:mm" }} zur Verfügung,
      können jedoch vorzeitig vom Unternehmen gelöscht werden.</span
    >
  </div>
} @else {
  <div class="deletion-notes-container">
    <span class="deletion-notes-text" i18n
      >Die Daten können ohne Ankündigung vorzeitig vom Unternehmen gelöscht
      werden.</span
    >
  </div>
}
