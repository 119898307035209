<mat-card class="private-card">
  <mat-card-header>
    <mat-card-title i18n>Anfrage nicht öffentlich</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p i18n>
      Die Daten zur Ihrer Anfrage wurden nicht freigegeben. Fragen Sie bei
      {{ companyDisplayName }} an um Einsicht in die Daten zu erhalten.
    </p>
  </mat-card-content>
  <!--<mat-card-footer class="private-card-footer">
    <button
      mat-flat-button
      color="primary"
      class="private-card-footer-button"
      (click)="inquireAccess()"
    >
      Zugang anfragen
    </button>
  </mat-card-footer>-->
</mat-card>
