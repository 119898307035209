<div class="company-info-container">
  <img
    *ngIf="companyLogoUrl"
    [src]="companyLogoUrl"
    alt="{{ companyDisplayName }}-Logo"
    class="company-logo"
  />

  <span class="company-display-name">{{ companyDisplayName }}</span>
</div>
<div class="watermark">
  <span i18n>bereitgestellt von</span>
  <a href="https://www.blankmile.com" target="_blank"> blankmile.com</a>
</div>
