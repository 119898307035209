import { Component, Inject } from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { ChatModule } from '@mad-tech/aidar-live-chat';
import { NgIf } from '@angular/common';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'chat-sheet',
  standalone: true,
  imports: [ChatModule, NgIf, MatButton],
  templateUrl: './chat-sheet.component.html',
  styleUrls: ['./chat-sheet.component.scss'],
})
export class ChatSheetComponent {
  protected inquiryId: string;
  protected displayInput = true;
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: { inquiryId: string; displayInput: boolean },
    private readonly sheet: MatBottomSheetRef<ChatSheetComponent>,
  ) {
    this.inquiryId = data.inquiryId;
    this.displayInput = data.displayInput;
  }

  closeSheet() {
    this.sheet.dismiss();
  }
}
