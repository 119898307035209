import { Component, Input } from '@angular/core';
import { InquirySummary } from '../../../model/inquiry-summary';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-deletion-info',
  standalone: true,
  imports: [DatePipe],
  templateUrl: './deletion-info.component.html',
  styleUrl: './deletion-info.component.scss',
})
export class DeletionInfoComponent {
  @Input() inquiry: InquirySummary;
}
