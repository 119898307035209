import { Injectable } from '@angular/core';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver-es';
import { BlobClient, BlobServiceClient } from '@azure/storage-blob';
import { firstValueFrom, Observable, timeout } from 'rxjs';
import { environment } from '../../environments/environment';
import { AttachmentService } from '../services/attachment.service';
import { Attachment } from '../model/attachment/attachment';
import { AssociatedCallPeriod } from '../model/attachment/associated-call-period';
import { CreateCustomerAttachmentRequest } from '../model/attachment/request/create-customer-attachment-request';
import { Author } from '../model/attachment/author';
import { Origin } from '../model/attachment/origin';
import { AttachmentUpload } from '../model/attachment/attachment-upload';

@Injectable()
export class AttachmentFileService {
  private readonly blobServiceClient: BlobServiceClient;

  constructor(private readonly attachmentService: AttachmentService) {
    this.blobServiceClient = new BlobServiceClient(environment.storagePath);
  }

  private static getFileNameWithoutExtension(filename: string) {
    return filename.substring(0, filename.lastIndexOf('.'));
  }

  private static getFileExtension(filename: string) {
    return filename.substring(filename.lastIndexOf('.') + 1, filename.length);
  }

  downloadAttachment(
    attachment: Attachment,
    attachmentDownloaded: (attachment: Attachment) => void,
  ) {
    const fileName = `${attachment.fileName}.${attachment.extension}`;
    saveAs(attachment.annotationBlobUrl ?? attachment.blobUrl, fileName);
    attachmentDownloaded(attachment);
  }

  public uploadAttachments(
    associatedCallPeriod: AssociatedCallPeriod,
    inquiryId: string,
    files: File[],
  ): Observable<Attachment> {
    return new Observable<Attachment>((observer) => {
      const uploadedAttachments = [];

      for (const file of files) {
        if (file) {
          const fileName = file.name;
          const createThumbnail = file.type.match('image/*') != null;

          this.createUploadFileRequest(inquiryId, fileName).then(
            async (attachmentUpload) => {
              const sasUri = attachmentUpload.sasUri;
              const res = await this.uploadAttachment(sasUri, file);
              if (!res) {
                observer.error(this.uploadError(fileName));
                return;
              }

              this.createCustomerAttachment(
                attachmentUpload.storageIdentifier,
                attachmentUpload.blobPath,
                associatedCallPeriod,
                inquiryId,
                fileName,
                createThumbnail,
              )
                .then((attachment) => {
                  uploadedAttachments.push(attachment);
                  observer.next(attachment);

                  if (uploadedAttachments.length >= files.length) {
                    observer.complete();
                  }
                })
                .catch(() => {
                  observer.error(this.uploadError(fileName));
                });
            },
          );
        }
      }
    });
  }

  async downloadAttachmentsAsZip(
    resultFileName: string,
    attachments: Attachment[],
    attachmentZipped: (attachment: Attachment) => void,
  ) {
    const zip = new JSZip();
    for (const attachment of attachments) {
      const containerClient = this.blobServiceClient.getContainerClient(
        attachment.containerName,
      );
      const blobClient = containerClient.getBlobClient(attachment.blobPath);

      const downloadBlockBlobResponse = await blobClient.download();
      const blob = await downloadBlockBlobResponse.blobBody;
      const fileName = `${attachment.fileName}.${attachment.extension}`;
      zip.file(fileName, blob);

      attachmentZipped(attachment);
    }

    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, resultFileName);
    });
  }

  private async createUploadFileRequest(
    inquiryIdentifier: string,
    fileNameWithExtension: string,
  ): Promise<AttachmentUpload> {
    return await firstValueFrom(
      this.attachmentService.createAttachmentUploadRequest(
        inquiryIdentifier,
        fileNameWithExtension,
      ),
    );
  }

  private async uploadAttachment(sasUri: string, file: File): Promise<boolean> {
    const blobBlobClient = new BlobClient(sasUri);
    const response = await blobBlobClient
      .getBlockBlobClient()
      .uploadData(file, { blobHTTPHeaders: { blobContentType: file.type } });

    return !response.errorCode;
  }

  private async createCustomerAttachment(
    storageIdentifier: string,
    blobPath: string,
    associatedCallPeriod: AssociatedCallPeriod,
    inquiryId: string,
    fileName: string,
    createThumbnail: boolean,
  ): Promise<Attachment> {
    const request: CreateCustomerAttachmentRequest = {
      storageIdentifier: storageIdentifier,
      blobPath: blobPath,
      period: associatedCallPeriod,
      inquiryIdentifier: inquiryId,
      fileName: AttachmentFileService.getFileNameWithoutExtension(fileName),
      extension: AttachmentFileService.getFileExtension(fileName),
      origin: Origin.Uploaded,
      author: Author.Customer,
      createThumbnail: createThumbnail,
    };

    return await firstValueFrom(
      this.attachmentService
        .createCustomerAttachment(request)
        .pipe(timeout(10_000)),
    );
  }

  private uploadError(fileName: string): FileAttachmentUploadError {
    return new FileAttachmentUploadError(
      fileName,
      `Error uploading file with name ${fileName}`,
    );
  }
}

export class FileAttachmentUploadError extends Error {
  affectedFileName: string;

  constructor(affectedFileName: string, message: string) {
    super(message);

    this.affectedFileName = affectedFileName;
  }
}
