<div class="container">
  <app-inquiry-deleted-header></app-inquiry-deleted-header>
  <span class="spacer"></span>
  <div class="link-expired-container">
    <ng-lottie
      width="20rem"
      [options]="{ path: 'assets/animations/lost.json' }"
    ></ng-lottie>
    <span class="link-expired-text" i18n> Dieser Link ist abgelaufen. </span>
  </div>
  <span class="spacer"></span>
  <div class="powered-by-container">
    <span class="powered-by-text"
      >Powered by <a href="http://blankmile.com">blankmile.&trade;</a></span
    >
  </div>
</div>
