import { ChatMessageDto } from '../../api/gen';
import { ChatMessage, Sender } from '@mad-tech/aidar-live-chat';

export class LiveChatDtoCasts {
  public static castFromDto(dto: ChatMessageDto): ChatMessage {
    return {
      id: dto.id,
      content: dto.message,
      sentOn: new Date(dto.sentOn),
      sender: dto.messageAuthor == 'Helper' ? Sender.Remote : Sender.Self,
    };
  }
}
