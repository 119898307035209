import { Component } from '@angular/core';
import { TinyLinkResolverService } from '../../api/gen';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { TenantInfoService } from '../util/tenant-info.service';

@Component({
  selector: 'app-resolver',
  templateUrl: './resolver.component.html',
  styleUrls: ['./resolver.component.scss'],
})
export class ResolverComponent {
  protected displayError = false;
  constructor(
    tenantService: TenantInfoService,
    activatedRoute: ActivatedRoute,
    tinyResolver: TinyLinkResolverService,
    private readonly router: Router,
  ) {
    activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      const inquiryId = params['inquiryId'];
      let tenantId = params['tenantId'];
      if (!inquiryId || !tenantId) {
        const path = this.router.url.slice(1);
        tenantId = tenantService.getTenantIdentifier();
        if (!path || !tenantId) {
          this.router.navigate(['/not-found']).then();
          return;
        }
        tinyResolver.notificationTinyUrlResolverGet(path, tenantId).subscribe(
          (data) => {
            this.navigateToJoinComponent(data.tenantId, data.inquiryId);
          },
          (_) => {
            this.displayError = true;
          },
        );
      } else {
        this.navigateToJoinComponent(tenantId, inquiryId);
      }
    });
  }

  private navigateToJoinComponent(tenantId: string, inquiryId: string): void {
    this.router
      .navigate(['/join'], {
        queryParams: {
          inquiryId: inquiryId,
          tenantId: tenantId,
        },
      })
      .then();
  }
}
