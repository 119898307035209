<mat-card>
  <mat-card-header>
    <mat-card-title i18n> Fehlende Berechtigungen</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <span i18n>Bitte gib folgende Berechtigungen frei:</span>
    <div class="permission-list">
      <ng-container
        *ngIf="!!micPermissionMissing"
        [ngTemplateOutlet]="permissionIcon"
        [ngTemplateOutletContext]="{
          title: titleMicrophone,
        }"
      ></ng-container>
      <ng-container
        *ngIf="!!camPermissionMissing"
        [ngTemplateOutlet]="permissionIcon"
        [ngTemplateOutletContext]="{
          title: titleCamera,
        }"
      ></ng-container>
    </div>
    <span i18n
      >Wir benötigten diese, um die Verbindung zu deinem Gesprächspartner
      aufzubauen.</span
    ><br />
    <span i18n>Du kontrollierst, was dieser sehen kann.</span>
    @if (isAndroid) {
      <div class="instruction-container">
        <span i18n>Bitte folgen Sie folgender Anleitung:</span>
        <ol>
          <li i18n>
            Klicke bitte auf das 🔒 neben der Addressleiste und gib die
            Berechtigungen frei.
          </li>
          <li i18n>Lade die Seite anschließend neu.</li>
        </ol>
        <div class="instruction-image-container">
          <img
            class="instruction-image"
            src="/assets/images/allow-permission-android.png"
            alt="instructions"
          />
        </div>
      </div>
    }
    @if (blockedOnSystemLevel) {
      <div class="instruction-container">
        <span i18n>Bitte folgen Sie folgender Anleitung:</span>
        <ol>
          <li i18n>Gehen Sie zu <b>Einstellungen</b> auf Ihrem Gerät.</li>
          <li i18n>
            Scrollen Sie nach unten und öffnen Sie die Registerkarte
            <b>Apps</b>.
          </li>
          <li i18n>
            Scrollen Sie nach unten und öffnen Sie die Einstellungen
            <b>{{ getBrowserName() }}</b
            >.
          </li>
          <li i18n>
            Stellen Sie sicher, dass Ihre Kamera und Ihr Mikrofon unter
            <i>Einstellungen für Websites</i> auf <b>Erlauben</b> oder
            <b>Fragen</b> gestellt ist.
          </li>
          <li i18n>Laden Sie die Seite neu.</li>
        </ol>
      </div>
    }
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-button color="primary" (click)="reloadPage()" i18n>
      Neu laden
    </button>
  </mat-card-actions>
</mat-card>

<ng-template #permissionIcon let-title="title">
  <div class="permission-chip">
    <mat-icon> warning_amber</mat-icon>
    <span>
      {{ title }}
    </span>
  </div>
</ng-template>
