import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ChatApiInterface, ChatMessage } from '@mad-tech/aidar-live-chat';
import { LiveChatService, LiveChatTranslationService } from '../../api/gen';
import { LiveChatDtoCasts } from './live-chat-dto-casts';

@Injectable({
  providedIn: 'root',
})
export class CustomerChatService implements ChatApiInterface {
  constructor(
    private readonly chatTranslationService: LiveChatTranslationService,
    private readonly liveChatService: LiveChatService,
  ) {}

  fetchMessagesForInquiry(inquiryId: string): Observable<ChatMessage[]> {
    return this.liveChatService
      .chatCustomerInquiryIdGet(inquiryId)
      .pipe(map((data) => data.map((x) => LiveChatDtoCasts.castFromDto(x))));
  }

  sendMessage(inquiryId: string, newMessage: string): Observable<ChatMessage> {
    return this.liveChatService
      .chatCustomerInquiryIdPost(inquiryId, {
        message: newMessage,
        inquiryId: inquiryId,
      })
      .pipe(map((x) => LiveChatDtoCasts.castFromDto(x)));
  }

  translateChatMessage(messageId: string, locale: string): Observable<string> {
    return this.chatTranslationService
      .chatTranslationsMessageIdPatch(messageId, {
        messageId: messageId,
        targetLocale: locale,
      })
      .pipe(map((x) => x.translation));
  }
}
