<mat-card class="chat-card">
  <mat-card-header>
    <mat-card-title i18n>Chatverlauf</mat-card-title>
  </mat-card-header>
  <mat-card-content class="chat-card-content">
    <span i18n>
      Der Chatverlauf zeigt die Originalnachrichten und kann nicht mehr
      bearbeitet werden.
    </span>
  </mat-card-content>
  <mat-card-footer class="chat-card-footer">
    <button
      mat-raised-button
      color="primary"
      class="chat-card-footer-button"
      (click)="openChat()"
    >
      <mat-icon>forum</mat-icon>
      <span i18n>Chatverlauf ansehen</span>
    </button>
  </mat-card-footer>
</mat-card>
