import { CommonModule, DatePipe, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SummaryRoutingModule } from './summary-routing.module';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';

import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LottieComponent } from 'ngx-lottie';
import { FileUploadModule } from '../file-upload/file-upload.module';
import { MissingPermissionsComponent } from '../shared/missing-permissions/missing-permissions.component';
import { AccessTokenLinkComponent } from './access-token-link/access-token-link.component';
import { InitialAccessComponent } from './initial-access/initial-access.component';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SummaryRoutingModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatMenuModule,
    MatDialogModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatCardModule,
    NgOptimizedImage,
    LottieComponent,
    FileUploadModule,
    MissingPermissionsComponent,
    AccessTokenLinkComponent,
    InitialAccessComponent,
  ],
  providers: [DatePipe],
  exports: [],
})
export class SummaryModule {}
