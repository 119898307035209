import { CreateAttachmentUploadRequest } from '../../../../api/gen';

export class CreateAttachmentUpload {
  inquiryIdentifier?: string;
  fileNameWithExtension?: string | null;

  static toRequest(
    model: CreateAttachmentUpload,
  ): CreateAttachmentUploadRequest {
    return {
      inquiryIdentifier: model.inquiryIdentifier,
      fileNameWithExtension: model.fileNameWithExtension,
    } as CreateAttachmentUploadRequest;
  }
}
