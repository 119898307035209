import { CompanySettingsPublicDto } from '../../api/gen';

export class CompanySettingsPublic {
  displayName?: string | null;
  logoUrl?: string | null;

  static fromDto(dto: CompanySettingsPublicDto): CompanySettingsPublic {
    return {
      displayName: dto.displayName,
      logoUrl: dto.logoUrl,
    } as CompanySettingsPublic;
  }
}
