import { Injectable } from '@angular/core';
import { InvitationStatusService as InvitationStatusApi } from '../../api/gen';
import { Observable } from 'rxjs';

@Injectable()
export class InvitationStatusService {
  constructor(private readonly invitationStatusService: InvitationStatusApi) {}

  public sendSmsInvitationClicked(
    inquiryId: string,
    tenantId: string,
  ): Observable<any> {
    return this.invitationStatusService.notificationStatusInquiryIdTenantIdPost(
      inquiryId,
      tenantId,
    );
  }
}
