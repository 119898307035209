import { Component, Input } from '@angular/core';
import {
  MatCard,
  MatCardContent,
  MatCardFooter,
  MatCardHeader,
  MatCardSubtitle,
  MatCardTitle,
} from '@angular/material/card';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-inquiry-private-card',
  standalone: true,
  imports: [
    MatCard,
    MatCardSubtitle,
    MatCardTitle,
    MatCardContent,
    MatCardHeader,
    MatButton,
    MatCardFooter,
  ],
  templateUrl: './inquiry-private-card.component.html',
  styleUrl: './inquiry-private-card.component.scss',
})
export class InquiryPrivateCardComponent {
  @Input() inquiryId: string;
  @Input() companyDisplayName: string;

  inquireAccess() {
    // TODO: Implement getting inquiry access
  }
}
