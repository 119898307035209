import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { MatButton, MatIconButton } from '@angular/material/button';
import {
  MatCard,
  MatCardContent,
  MatCardFooter,
  MatCardHeader,
  MatCardImage,
  MatCardSubtitle,
  MatCardTitle,
} from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { InquirySummary } from '../../../model/inquiry-summary';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { Attachment } from '../../../model/attachment/attachment';
import { BehaviorSubject, take } from 'rxjs';
import { AttachmentFileService } from '../../../file-upload/attachment-file.service';
import { InquirySummaryService } from '../../../services/inquiry-summary.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateFormatterService } from '../../../util/date-formatter.service';
import { FileUploadModule } from '../../../file-upload/file-upload.module';
import { AttachmentContentType } from '../../../model/attachment/attachment-content-type';

@Component({
  selector: 'app-attachments-gallery',
  standalone: true,
  imports: [
    AsyncPipe,
    MatButton,
    MatCard,
    MatCardImage,
    MatCardSubtitle,
    MatIcon,
    NgIf,
    MatMenuTrigger,
    MatMenu,
    MatIconButton,
    MatMenuItem,
    FileUploadModule,
    NgForOf,
    MatCardTitle,
    MatCardFooter,
    MatCardHeader,
    MatCardContent,
  ],
  templateUrl: './attachments-gallery.component.html',
  styleUrl: './attachments-gallery.component.scss',
  providers: [
    AttachmentFileService,
    InquirySummaryService,
    DateFormatterService,
  ],
})
export class AttachmentsGalleryComponent {
  @Input() inquiry: InquirySummary;
  @Output() filesUploaded: EventEmitter<boolean>;

  protected readonly downloading$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  protected readonly alreadyDownloadedAttachments$: BehaviorSubject<string[]> =
    new BehaviorSubject<string[]>([]);

  constructor(
    private readonly fileService: AttachmentFileService,
    private readonly inquirySummaryService: InquirySummaryService,
    protected readonly dateFormatterService: DateFormatterService,
    private readonly snackBar: MatSnackBar,
  ) {
    this.filesUploaded = new EventEmitter();
  }

  onDownloadAttachment(attachment: Attachment) {
    this.downloading$.next(true);
    this.fileService.downloadAttachment(attachment, (attachment) => {
      this.setAlreadyDownloaded(attachment);
    });
    this.downloading$.next(false);
  }

  onAlreadyDownloadedAttachment(attachment: Attachment) {
    const snackBar = this.snackBar.open(
      $localize`Datei wurde bereits heruntergeladen.`,
      $localize`Erneut laden`,
      { duration: 5_000 },
    );
    snackBar
      .onAction()
      .pipe(take(1))
      .subscribe(() => {
        this.onDownloadAttachment(attachment);
      });
  }

  emitFilesUploaded() {
    this.filesUploaded.emit(true);
  }

  private setAlreadyDownloaded(attachment: Attachment) {
    const downloadedAttachments = this.alreadyDownloadedAttachments$.value;
    if (downloadedAttachments.indexOf(attachment.attachmentIdentifier) < 0) {
      downloadedAttachments.push(attachment.attachmentIdentifier);
      this.alreadyDownloadedAttachments$.next(downloadedAttachments);
    }
  }

  protected readonly AttachmentContentType = AttachmentContentType;
}
