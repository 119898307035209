import { CompanyService as Api } from '../../api/gen';
import { map, Observable } from 'rxjs';
import { CompanySettingsPublic } from '../model/company-settings-public';
import { Injectable } from '@angular/core';

@Injectable()
export class CompanyService {
  constructor(private readonly companyService: Api) {}

  getPublicCompanyInformation(): Observable<CompanySettingsPublic> {
    return this.companyService
      .customerCompanyGet()
      .pipe(map((dto) => CompanySettingsPublic.fromDto(dto)));
  }
}
