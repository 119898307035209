import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { AttachmentDto } from '../../../api/gen';

@Component({
  selector: 'app-upload-files-snackbar',
  templateUrl: './upload-files-snackbar.component.html',
  styleUrls: ['./upload-files-snackbar.component.scss'],
})
export class UploadFilesSnackbarComponent {
  protected uploadData: BehaviorSubject<UploadFilesSnackbarData>;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: UploadFilesSnackbarData,
  ) {
    this.uploadData = new BehaviorSubject<UploadFilesSnackbarData>(data);
  }

  onAttachmentUploaded(attachment: AttachmentDto) {
    const oldSnackbarData = this.uploadData.value;
    const newSnackbarData = {
      numberOfFilesAlreadyProcessed:
        oldSnackbarData.numberOfFilesAlreadyProcessed + 1,
      numberOfFilesToProcess: oldSnackbarData.numberOfFilesToProcess,
    } as UploadFilesSnackbarData;

    this.uploadData.next(newSnackbarData);
  }
}

export interface UploadFilesSnackbarData {
  numberOfFilesAlreadyProcessed: number;
  numberOfFilesToProcess: number;
}
