import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { UploadFilesSnackbarComponent } from './upload-files-snackbar/upload-files-snackbar.component';
import { AttachmentFileService } from './attachment-file.service';

@NgModule({
  declarations: [FileUploadComponent, UploadFilesSnackbarComponent],
  imports: [CommonModule],
  providers: [AttachmentFileService],
  exports: [FileUploadComponent],
})
export class FileUploadModule {}
